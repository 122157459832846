import {
  EnvironmentSettings,
  EnvironmentType,
} from '@skyfit/core-data-library';

export const baseProductionEnvironment: EnvironmentSettings = {
  aaptivServiceUrl: 'https://skyfit.aaptiv.com',
  productionMode: true,
  repTime: 4,
  sentryDsn:
    'https://dc1bfd4d72e948b1928605beb326c1ea@o367782.ingest.us.sentry.io/6724194',
  serverEnvironment: EnvironmentType.production,
  googleAnalyticsTrackingId: 'G-22FPWC3Y1D',
};
